export const EVENT_NAMES = {
  INVESTOR_REGISTERED: 'Investor Registered',
  LEAD_CREATION_REQUESTED: 'Lead Creation Requested',
  INVESTOR_SIMULATED: 'Investor Simulated',
  LEGAL_POLICIES_ACCEPTED: 'Legal Policies Accepted'
}

export const POLICIES = {
  'terminos y condiciones': {
    co: 'COT0102',
    mx: 'MXT0102'
  },
  'politicas de tratamiento de datos': {
    co: 'COP0201',
    mx: 'MXP0201'
  }
}

export const SCREEN = {
  HOME: 'HOME',
  PDP_INVESTORS: 'PDP_INVESTORS',
  INVESTMENT_SIMULATOR: 'INVESTMENT_SIMULATOR',
  INVESTMENT_QUIZ: 'INVESTMENT_QUIZ',
  INVESTMENT_QUOTATION_PLAN: 'INVESTMENT_QUOTATION_PLAN',
  SEARCH_RESULTS: 'SEARCH_RESULTS'
}

export const SCREEN_CTA = {
  LET_ME_KNOW_FORM: 'LET_ME_KNOW_FORM',
  SCHEDULE_BUTTON: 'SCHEDULE_BUTTON',
  INVESTMENT_SCHEDULE_BUTTON: 'INVESTMENT_SCHEDULE_BUTTON',
  INVESTMENT_LET_ME_KNOW_BUTTON: 'INVESTMENT_LET_ME_KNOW_BUTTON',
  INVESTMENT_WANT_TO_INVEST_BUTTON: 'INVESTMENT_WANT_TO_INVEST_BUTTON',
  INVESTMENT_SEE_OPPORTUNITIES: 'INVESTMENT_SEE_OPPORTUNITIES',
  INVESTMENT_SHOW_PAYMENT_PLAN: 'INVESTMENT_SHOW_PAYMENT_PLAN',
  INVESTMENT_PRODUCT_BOT: 'INVESTMENT_PRODUCT_BOT',
  INVESTMENT_CONTACT_FORM: 'INVESTMENT_CONTACT_FORM',
  INVESTMENT_WEB_CHAT: 'INVESTMENT_WEB_CHAT',
  INVESTMENT_FACE_TO_FACE_FORM: 'INVESTMENT_FACE_TO_FACE_FORM',
  INVESTMENT_REGISTRATION_FORM: 'INVESTMENT_REGISTRATION_FORM',
  INVESTMENT_DIRECT_SELL_FORM: 'INVESTMENT_DIRECT_SELL_FORM'
}

export const LISTING_SOURCE = {
  SOBREPLANOS: 'SOBREPLANOS',
  LISTING_SERVICE: 'LISTING_SERVICE',
  PROPERTY_CATALOG: 'PROPERTY_CATALOG'
}

export const TYPE_ATTENTION = {
  PRODUCT_BOT: 'PRODUCT_BOT',
  PRODUCT_WEB: 'PRODUCT_WEB',
  COMMERCIAL_OPERATIONS: 'COMMERCIAL_OPERATIONS'
}

export const SAM_GPT_FLOW_ID = {
  PDP_WEB_CHAT_V1: 'PDP_WEB_CHAT_V1'
}

export const SAM_GPT_FLOW_NAME = {
  PDP_WEB_CHAT: 'PDP_WEB_CHAT'
}

export const SAM_GPT_FLOW_STAGE = {
  GREETING_MESSAGE: 'GREETING_MESSAGE',
  USER_MESSAGE: 'USER_MESSAGE',
  BOT_MESSAGE: 'BOT_MESSAGE'
}

export const SAM_GPT_INTENT = {
  1: 'SCHEDULE',
  2: 'BRIEF',
  3: 'BLUEPRINTS',
  4: 'PHOTOS',
  5: 'INTRODUCTION',
  6: 'GENERAL_QUESTION',
  7: 'ASSIGN_TO_SALES_AGENT',
  8: 'RECOMMENDATION',
  9: 'QUALIFICATION',
  10: 'PROFILING',
  11: 'DISQUALIFY_NEEDS_TO_SELL',
  12: 'SCHEDULE_DAY_SELECTION',
  13: 'SCHEDULE_HOUR_SELECTION',
  14: 'SCHEDULE_CREATED',
  15: 'CLUSTER_RECOMMENDATIONS',
  16: 'CONSTRUCTION_PERMISSION',
  17: 'CHAMBER_OF_COMMERCE',
  18: 'CERTIFICATE_OF_TRADITION',
  19: 'RFC',
  20: 'CONTRACT',
  21: 'RESUME_BUILDER',
  22: 'QUOTATION_INTRO',
  23: 'PAYMENT_PLAN',
  24: 'CONSULT_TYPOLOGIES',
  25: 'CONSULT_FRACTION_DETAILS',
  26: 'CLUSTER_RECOMMENDATIONS_INTRODUCTION',
  27: 'OPEN_RECOMMENDATIONS',
  28: 'PROJECT_INTRODUCTION',
  29: 'QUOTATION_PLAN',
  30: 'GENERIC_RECOMMENDATIONS',
  31: 'RE_SCHEDULED_MEET',
  32: 'CONFIRM_VISIT',
  33: 'IN_PERSON_VISIT_SCHEDULED',
  34: 'IN_PERSON_VISIT_DATE_SELECTION',
  36: 'BROCHURE',
  37: 'GREETING',
  38: 'CALL_CONTACT',
  39: 'WHATSAPP_CONTACT'
}

export const USER_INTENT = {
  ASK: 'ASK'
}

export const SAM_ATTACHMENT_TYPE = {
  1: 'PHOTO',
  2: 'VIDEO',
  3: 'DOCUMENT'
}

function getCookie (name) {
  const value = `; ${document.cookie}`

  const parts = value.split(`; ${name}=`)

  if (parts.length === 2) return parts.pop().split(';').shift()
}

export const getAnonymousId = () => {
  return getCookie('ajs_anonymous_id')
}

export const opportunityTypeEventMap = {
  living: 'LIVING',
  investing: 'INVESTMENT'
}
